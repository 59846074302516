import React from "react";
import ProfileCard from "./ProfileCard";
import Footer from "./Footer";



const containerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyle = {
  flex: 1,
};

export default function About() {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <div>
          <header className="mt-16 py-10 p-10">
            <div className="container mx-auto">
              <h1 className="text-4xl font-bold tracking-tight sm:text-6xl text-center lg:pl-24 text-navy">
                About Muteresi
              </h1>
              <p className="mt-6 text-xl leading-8 text-center lg:text-center lg:pl-24 text-base text-navy">
                Empowering businesses to manage their finances more efficiently and effectively.
              </p>
            </div>
          </header>

          <main className="container mx-auto px-8 py-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h2 className="text-center lg:text-left mt-10 lg:pl-24 text-4xl font-bold tracking-tight text-navy sm:text-4xl">Our Story</h2>
                <p className="text-base text-navy mt-6 ">
                  addressing the invoicing challenges faced by Zimbabweans.
                  Recognizing the struggle people experienced, John created a unique solution
                  for creating and sending invoices via SMS on mobile phones. Since then, Muteresi has
                  evolved from a small startup to become a leading invoicing platform in the industry.

                </p>
              </div>
              <div>
                <h2 className="text-center lg:text-left mt-10 lg:pl-24 text-4xl font-bold tracking-tight text-navy sm:text-4xl">Our Team</h2>
                <p className="text-base text-navy mt-6">
                  designers, and financial experts who are driven to empower businesses.
                  With a strong emphasis on transparency, simplicity, and innovation,
                  we are committed to delivering an exceptional invoicing experience
                  to our valued customers.
                </p>
              </div>
            </div>
          </main>
          <div>
            <ProfileCard />
          </div>
          <Footer />
        </div>
      </div>
    </div>

  );
}