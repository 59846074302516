import React from 'react';

const SocialMediaLink = ({ href, logoSrc, altText, bgColorClass, hoverColorClass }) => (
  <ul>
    <a href={href} target='_blank' rel="noopener noreferrer" className={`pr-8 mr-4 md:mr-6 ${hoverColorClass}`}>
      <img src={logoSrc} className={`h-12 w-12 bg-white rounded-full mt-5 ${bgColorClass}`} alt={altText} />
    </a>
  </ul>
);

export default SocialMediaLink;