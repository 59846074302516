import React from 'react';
import Typewriter from "typewriter-effect";
import Footer from '../../components/Footer';

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#F5F5DC", // Light beige background color
};

const contentStyle = {
  flex: 1,
  padding: "2rem", // Add padding for better spacing
};

export default function Home() {
  const imageUrl = 'image6.jpg'; // Ensure you have an appropriate image for the wetland theme

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <div className="flex flex-col lg:flex-row items-center justify-center mb-8 gap-x-4 py-10">
          <div className="flex-1 flex flex-col items-center justify-center">
            <header className="mt-16 text-center text-3xl lg:pl-24 font-bold tracking-tight text-darkgreen sm:text-4xl">
              <span>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString('Welcome to Muteresi,')
                      .pauseFor(1000)
                      .typeString(' ')
                      .typeString('A Powerful Invoice Creating App!')
                      .start();
                  }}
                  options={{
                    cursor: '',
                    delay: 80, // Adjust the delay between each character
                    autoStart: true,
                  }}
                />
              </span>
            </header>

            <section className="mt-6 text-left leading-8 text-base text-darkgreen">
              <ul>
                <li>Effortlessly create professional invoices</li>
                <li>Customize invoices with your branding</li>
                <li>Send invoices directly to clients</li>
                <li>Track invoice status and payment history</li>
                <li>Create invoice directly from your phone or desktop</li>
              </ul>
            </section>
            <div className="mt-10">
              <a
                href="https://www.facebook.com/profile.php?id=61550738293751"
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-8 rounded mt-10"
              >
                Sign Up
              </a>
            </div>
          </div>

          <div className="overflow-hidden relative flex-1 items-center justify-center mr-6">
            <img
              src={imageUrl}
              alt="Background"
              className='mt-12 object-cover w-full rounded-2xl shadow-lg'
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
