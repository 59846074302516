import React from "react";
import Footer from "./Footer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyle = {
  flex: 1,
};

const Faq = () => {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <section className="py-2 mb-6 sm:py-6 lg:py-12 ">
          <div className="max-w-5xl pt-24 px-4 mx-auto sm:px-6 lg:px-8">
            <a id="faq">
              <div className="max-w-2xl mx-auto text-center">
                <h2 className="text-3xl font-bold leading-tight text-navy sm:text-4xl lg:text-5xl">
                  Frequently Asked Questions{" "}
                </h2>
                <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-navy">
                  Welcome to the Frequently Asked Questions (FAQ) page for our invoicing app, Muteresi! Below, you'll find answers to some of the common queries our users have. If you don't see your question answered here, feel free to reach out to our support team for further assistance.
                </p>
              </div>

              <div className="grid grid-cols-1 mt-12 md:mt-20 md:grid-cols-2 gap-y-16 gap-x-20">
                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      1. What is Muteresi and how can it benefit me?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      Muteresi is a powerful invoicing application designed to streamline your billing process. It helps freelancers, small businesses, and entrepreneurs create professional invoices, track payments, manage clients, and more. By using Muteresi, you can save time, reduce administrative overhead, and ensure accurate and timely billing.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      2. Is Muteresi suitable for my business size and industry?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      Yes, Muteresi is suitable for businesses of all sizes and across various industries. Whether you're a freelancer, consultant, contractor, or small business owner, our app is customizable to meet your invoicing needs. From graphic designers to legal professionals, Muteresi caters to a diverse range of industries.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      3. How secure is my data on Muteresi?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      We take the security and privacy of your data seriously. Muteresi utilizes industry-standard encryption protocols to safeguard your information. Additionally, we adhere to strict data protection regulations to ensure the confidentiality and integrity of your data at all times.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      4. Can I customize my invoices with Muteresi?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      Absolutely! With Muteresi, you have full control over the design and content of your invoices. You can customize your invoice templates with your logo, brand colors, and personalized messaging. Our intuitive interface makes it easy to create professional-looking invoices that reflect your brand identity.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      5. Does Muteresi offer recurring billing and automatic payment reminders?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      Yes, Muteresi offers features for recurring billing and automatic payment reminders. You can set up recurring invoices for clients with ongoing services or subscriptions, saving you time and effort. Additionally, our app can automatically send payment reminders to clients to help you stay on top of overdue payments.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      6. How can I get started with Muteresi?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      Getting started with Muteresi is easy! Simply sign up for an account on our website or download the mobile app from the App Store or Google Play Store. Once you're logged in, connect your Telegram account, and start managing your invoices seamlessly within the Telegram app.
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="ml-4">
                    <p className="text-2xl font-semibold text-navy">
                      7. Does Muteresi offer customer support?
                    </p>
                    <p className="mt-4 text-base text-navy">
                      Yes, we provide dedicated customer support to assist you with any questions or issues you may encounter. You can reach out to our support team via email, live chat, or phone during our business hours. Additionally, we offer comprehensive help documentation and video tutorials to help you make the most of Muteresi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4 pt-12">
                <p className="mt-4 text-base text-navy">
                  We hope this FAQ section has provided you with valuable insights into Muteresi and its features. If you have any further questions or require assistance, don't hesitate to contact us. Thank you for choosing Muteresi for your invoicing needs!</p>
              </div>

            </a>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;