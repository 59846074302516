import React from 'react';
import { Typography } from "@material-tailwind/react";

function ProfileCard() {
  return (
    <section class="">
      <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div class="mx-auto mb-8 max-w-screen-sm lg:mb-16">
          <h2 class="font-poppins text-6xl sm:text-5xl mb-4 tracking-tight font-medium text-navy dark:text-navy animate-pulse">
            The dream team
          </h2>
        </div>
        <div class="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <div class="text-center text-navy dark:text-navy">
            <img className="rounded-2xl" src="image06.jpg" alt="profile-picture" />
            <h3 class="mb-1 text-2xl font-bold tracking-tight text-navy dark:text-navy">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-navy">
                Natalie Paisley
              </Typography>
              <Typography color="blue-gray" className="font-medium text-navy" textGradient>
                CEO / Co-Founder
              </Typography>
            </h3>
          </div>
          <div class="text-center text-navy dark:text-navy">
            <img className="rounded-2xl" src="image03.jpg" alt="profile-picture" />
            <h3 class="mb-1 text-2xl font-bold tracking-tight text-navy dark:text-navy">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-navy">
              Rene Gill
              </Typography>
              <Typography color="blue-gray" className="font-medium text-navy" textGradient>
              CTO / Co-founder
              </Typography>
            </h3>
          </div>
          <div class="text-center text-navy dark:text-navy">
            <img className="rounded-2xl" 
            src="image05.jpg" alt="profile-picture" />
            <h3 class="mb-1 text-2xl font-bold tracking-tight text-navy dark:text-navy">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-navy">
              Elvia Berger
              </Typography>
              <Typography color="blue-gray" className="font-medium text-navy" textGradient>
              SEO & Marketing
              </Typography>
            </h3>
          </div>
          <div class="text-center text-navy dark:text-navy">
            <img className="rounded-2xl" 
            src="image04.jpg" 
            alt="profile-picture" />
            <Typography variant="h4" color="blue-gray" className="mb-2 text-navy">
            Gerlinde Taferner
            </Typography>
            <Typography color="blue-gray" className="font-medium text-navy" textGradient>
            Sales
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProfileCard;