import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="container mx-auto p-8 ">
      <h1 className="text-4xl font-bold tracking-tight sm:text-6xl mb-6 mt-20 text-center text-navy"> Our Cookie Policy</h1>
      <p className="mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
        Welcome to Muteresi. This page explains how we use cookies on our website.
        By using our website, you agree to the use of cookies as described in this policy.
      </p>

      <h2 className="mt-6 mb-3 text-2xl font-semibold lg:pl-24 tracking-tight text-navy sm:text-3xl">What are Cookies?</h2>
      <p className="mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
        Cookies are small text files that are stored on your device when you visit a website.
        They are widely used to enhance your browsing experience by remembering your preferences and activities.
      </p>

      <h2 className="mt-6 mb-3 text-2xl font-semibold lg:pl-24 tracking-tight text-navy sm:text-3xl">Types of Cookies We Use</h2>
        <p className="mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
          <strong>Necessary Cookies:</strong>
          <br/> These cookies are essential for the proper functioning of our website.
          They enable core functionalities such as security, network management, and accessibility.
        </p>

      <p className="mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
      <strong>Managing Cookies:</strong>
        You can control and/or delete cookies as you wish. You can delete all cookies that are already on your device,
        and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually
        adjust some preferences every time you visit our site.
      </p>

      
      <p className="mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
      <strong>Changes to This Policy:</strong>
      <br/> We may update our Cookie Policy from time to time. Any changes will be posted on this page with the last updated date.
      </p>

        
        <p className="mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
          <strong>Questions or Concerns:</strong>
          <br/>If you have any questions about our Cookie Policy, please contact us at <a href="mailto:support@lekkercommece.co.za" className='text-blue-500'>support@lekkercommece.co.za</a>.</p>
      </div>
  );
};

export default CookiePolicy;
