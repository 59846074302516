import React from "react";
import Logo from "../images/chatbot.webp";
import { NavLink } from "react-router-dom";




function NavBar() {

  return (
    <nav className="bg-blue-500 fixed top-0 left-0 right-0 z-10 px-2 sm:px-4 lg:px-8">
      <div className="px-2 sm:px-2 lg:px-6">
        <div className="flex justify-between h-20">
          <div className="flex flex-wrap justify-between items-center">
             <NavLink to="/" className="flex items-center">
              <img className="h-8 w-auto object-cover" src={Logo} alt="chatbot" />
               </NavLink>
               </div>
                  <div className=" lg:ml-6 lg:block mt-5">
                  <div className="flex space-x-4">
                     <NavLink to="/" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white">
                      Home
                    </NavLink>
                    <NavLink to="/about" className="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-700 hover:text-white">
                      About Us
                    </NavLink>
                    <NavLink
                      to="/faq"
                      className="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-700 hover:text-white"
                    >
                      FAQ
                    </NavLink>
              {/*<NavLink
                      to="/cookie_policy"
                      className="rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-gray-700 hover:text-white"
                    >
                      Cookies
  </NavLink>*/}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;

















