
import React from 'react';
import Footer from './Footer';

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyle = {
  flex: 1,
};



const PrivacyPolicy = () => {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <div className="container mx-auto my-8 p-8 bg-white">

          <header className="mt-16 py-10 p-10">
            <div className="container mx-auto">
              <h1 className="text-4xl font-bold tracking-tight sm:text-6xl text-center lg:pl-24 text-navy">
                Our Privacy Policy
              </h1>
            </div>
          </header>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-navy lg:text-left lg:pl-24 ">1. Introduction</h2>
            <p className="mt-6  leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">Welcome to Muteresi (the "easier way to invoice your clients"). This privacy policy describes how we collect, use, and protect your personal information.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-navy lg:text-left lg:pl-24 ">2. Information We Collect</h2>
            <p className="mt-6  leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">We collect information that you provide directly to us, such as your name, email address, and any other information you choose to provide.</p>
            <p className="mt-6 leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">We may also automatically collect certain information when you visit our Website, such as your IP address, browser type, and operating system.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-navy lg:text-left lg:pl-24 ">3. Contact Us</h2>
            <p className="mt-6  leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">If you have any questions about this privacy policy, you can contact us at <span className='text-blue-500'> <a href="mailto:support@lekkercommece.co.za" className='text-blue-500'>support@lekkercommece.co.za</a>.</span></p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
