import React from "react";
import Footer from "./Footer";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyle = {
  flex: 1,
};


const RefundPolicy = () => {
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <header className="mt-16 py-10 p-10">
          <div className="container mx-auto">
            <h1 className="text-4xl font-bold tracking-tight sm:text-6xl text-center lg:pl-24 text-navy">
              Refundy Policy
            </h1>
          </div>
        </header>
        <div className="py-5">
          <p className=" mt-6 text-xl leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
            Muteresi does not offer refunds, but if you have a paid subscription
            and wish to switch to a free subscription, you have a couple of
            options:
          </p>
        </div>

        <div>
          <div className="py-5">
            <p className=" mt-6 leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
              1. You can downgrade to a Free Plan immediately. If there is more
              than one day until your renewal date, we will prorate the remaining
              balance on your account and hold it as credits for you. You can use
              these credits towards a reactivation of your paid subscription in
              the future.
            </p>
          </div>

          <div className="py-5">
            <p className=" mt-6 leading-8 text-center lg:text-left lg:pl-24 text-base text-navy">
              2. We can automatically downgrade you to the Free Plan at your
              next renewal date. Your subscription will remain active at its
              current level through the end of the billing period that you've
              already paid for.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
