import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './Features/Pages/Home';
import FAQ from './components/FAQ';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import About from './components/About';
import CookiePolicy from './components/CookiePolicy';
import Terms from './components/terms';
import Refund from './components/Refund';

const App = () => {
  return (
    <HashRouter>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/cookie_policy" element={<CookiePolicy />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
      </Routes>
    </HashRouter>
  );
};

export default App;
