import React from "react";
import { Link } from "react-router-dom";
import FacebookLogo from "../assets/fblogo.png";
import XLogo from "../assets/xlogo.png";
import SocialMediaLink from "./SocialMediaLink";

function Footer() {
  return (
    <footer className="bg-blue-500 rounded-t-3xl">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8 flex flex-wrap justify-between">
        <div className="flex items-center mb-4">
          <img src={XLogo} className="object-cover h-20 w-auto mr-5 ml-5" alt="JusaMusha Logo" />
        </div>
        <div className="flex flex-col">
        <h2 class="mb-6 text-sm font-semibold text-navy uppercase">Company</h2>
          <ul className="text-white font-medium">
            <li className="mb-4">
              <Link to="/" className="hover:underline">Home</Link>
            </li>
            <li className="mb-4">
              <Link to="/about" className="hover:underline">About Us</Link>
            </li>
            <li className="mb-4">
              <Link to="/faq" className="hover:underline">FAQ</Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col">
        <h2 class="mb-6 text-sm font-semibold text-navy uppercase">Legal</h2>
          <ul className="text-white font-medium">
            <li className="mb-4">
              <Link to="/refund" className="hover:underline">Refund Policy</Link>
            </li>
            <li className="mb-4">
              <Link to="/privacy_policy" className="hover:underline">Privacy Policy</Link>
            </li>
            <li className="mb-4">
              <Link to="/terms" className="hover:underline">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
        <div className="">
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white mr-5">
              <span className="pr-8 text-xl">Follow Us</span>
              <SocialMediaLink
              href="https://www.facebook.com/profile.php?id=61554656188528"
              logoSrc={FacebookLogo}
              altText="Facebook Logo"
              bgColorClass="hover:bg-amber"
            />
          </ul>
          </div>
        </div>
      <hr className="my-6 border-white sm:mx-auto dark:border-white lg:my-8" />
      <span className="block text-sm text-white sm:text-center dark:text-white pb-4">
        © {new Date().getFullYear()} <a href="https://bitwisesoftware.co.za" className="hover:underline">Bitwise Software Solutions™</a> Trading Company. All Rights Reserved.
      </span>

    </footer>
  );
}

export default Footer;
